import { initObserver, sel, selAll } from './utils'

export default function Intake() {
  sel('.intake__steps__item.is--active')?.classList.remove('is--active')
  sel('.intake__steps__item')?.classList.add('is--active')
  const sideSteps$a = selAll('.intake__steps__item')
  const dates$a = selAll('input.is--date')
  let currentStep = 1
  let dateSelected

  dates$a.forEach((dateInput) => {
    let isActive = false
    // dateInput.onfocus = () => {
    //   isActive = true
    // }
    dateInput.setAttribute('pattern', `\\d{2}\/\\d{2}\/\\d{4}`)
    const rows$ = dateInput.closest('.form__rows')
    // Create a hidden input element
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'text'
    hiddenInput.setAttribute('hidden', '')
    // hiddenInput.setAttribute('placeholder', '')
    hiddenInput.setAttribute('date-active', 'false')
    // hiddenInput.setAttribute('pattern', `\\d{2}-[A-Za-z]{3}-\\d{4}`)
    // if (dateInput.hasAttribute('required')) {
    //   hiddenInput.setAttribute('required', '')
    //   dateInput.removeAttribute('required')
    // }

    // Remove the name attribute from the date input
    const name = dateInput.getAttribute('name')
    dateInput.removeAttribute('name')
    if (dateInput.hasAttribute('readonly')) dateInput.removeAttribute('readonly')

    // Set the id and name attributes of the date input to the previous name
    dateInput.setAttribute('id', name)
    hiddenInput.setAttribute('name', name)

    dateInput.parentNode.append(hiddenInput)

    // Add an input event listener to the date input
    // let mod

    dateInput.addEventListener('pointerdown', () => {
      console.log('start')
      setTimeout(() => {
        const mod = sel('.datepicker-container:not(.datepicker-hide)')
        if (mod?.hasAttribute('data-name') || !mod) return
        console.log('dd register')
        mod.setAttribute('data-name', name)
        console.log('click')
        console.dir(mod)

        mod.addEventListener('click', () => {
          console.log('dd')
          if (!mod?.hasAttribute('tabindex')) mod?.setAttribute('tabindex', '0')

          mod?.focus()
          setTimeout(() => {
            updateDate()
          }, 800)
        })
        mod.addEventListener(
          'blur',
          () => {
            console.log('dd blur')
            // mod.removeEventListener('pointerDown', handleDateClick)
            isActive = true
            hiddenInput.setAttribute('date-active', 'true')
          },
          { once: true }
        )
      }, 500)
    })

    dateInput.addEventListener('blur', () => {
      console.log('date blur')
      updateDate()
    })
    dateInput.addEventListener('input', () => {
      console.log('input')
      updateDate()
    })

    function updateDate() {
      console.log('handleDateInput', dateSelected, dateInput.value)

      if (dateSelected && hiddenInput.value && dateSelected === dateInput.value) return console.log('same date')
      if (dateInput.value === '') {
        hiddenInput.value = ''
        return console.log('empty date')
      }
      const date = new Date(dateInput.value)
      if (isNaN(date)) return console.log('invalid date')

      dateSelected = dateInput.value
      console.log('up', date)

      const day = ('0' + date.getDate()).slice(-2)
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const month = months[date.getMonth()]
      const year = date.getFullYear()

      const formattedDate = `${day}-${month}-${year}`

      // Update the hidden input value
      hiddenInput.value = formattedDate
      rows$.querySelector('select, textarea, input:not([data-toggle="datepicker"], [hidden])')?.dispatchEvent(new Event('input', { bubbles: true }))
    }
  })
  const steps$a = selAll('[data-form="step"]')
  // Create a MutationObserver instance
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        const element = mutation.target
        if (!element.getAttribute('style')) {
          selAll('.is--active[data-form="step"]').forEach((step) => {
            step.classList.remove('is--active')
          })
          element.classList.add('is--active')
          const step = element.getAttribute('data-step').split('-')[0]
          if (step !== currentStep) {
            sideSteps$a[currentStep - 1].classList.remove('is--active')
            sideSteps$a[step - 1].classList.add('is--active')
            currentStep = step
          }
        }
      }
    }
  })
  selAll('[data-form="next-btn"]').forEach((btn) => {
    btn.addEventListener('click', () => {
      lenis.scrollTo(0)
    })
  })

  // Start observing the `style` attribute for each element in `steps$a`
  steps$a.forEach((step) => {
    observer.observe(step, { attributes: true })
  })

  // Consent - last step
  /*
  const contacts$ = selAll('.form__contact__item')
  let showPersons = 1
  const totalPersons = contacts$.length
  contacts$.forEach((inputW) => {
    const person = inputW.dataset.person
    const checkboxW$ = sel(`.form__consent__behalf>*[data-person="${person}"]`)
    if (person !== '1') {
      inputW.classList.add('hide')
      checkboxW$.classList.add('hide')
    }
    const inputName$ = inputW.querySelector('input.is--name')
    inputName$.addEventListener('change', function () {
      const name = this.value
      const checkboxName$ = checkboxW$.querySelector('label.is--main')
      checkboxName$.textContent = name
    })
  })

  const addPerson$ = sel('.form__consent__add-a')
  addPerson$.addEventListener('click', function () {
    showPersons++
    sel(`.form__contact__item[data-person="${showPersons}"]`).classList.remove('hide')
    sel(`.form__consent__behalf>*[data-person="${showPersons}"]`).classList.remove('hide')
    console.log(showPersons, totalPersons)
    if (showPersons >= totalPersons) {
      this.classList.add('hide')
    }
  })
    */
}
